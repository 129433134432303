<template lang="pug">
.form.form-review
  .form__block(v-show="!sendForm")
    .form__title(v-html="formTitle")
    .form__fields
      .form__field
        .input-text(:class="{'input-text-focus': (fieldName.length >= 3) && (fieldName.length <= 40), 'input-text-error': fieldError && v$.fieldName.$invalid}")
          input.input-text__input(
            type="text"
            v-model="fieldName"
            :placeholder="formFieldNamePlaceholder"
          )

      .form__field(:class="{'input-text-focus': !v$.fieldEmail.$invalid, 'input-text-error': fieldError && v$.fieldEmail.$invalid}")
        .input-text
          input.input-text__input(
            type="email"
            v-model="fieldEmail"
            :placeholder="formFieldEmailPlaceholder"
          )

      .form__field
        .input-textarea(:class="{'input-textarea-focus': !v$.fieldText.$invalid, 'input-textarea-error': fieldError && v$.fieldText.$invalid}")
          textarea.input-textarea__input(
            v-model="fieldText"
            :placeholder="formFieldTextPlaceholder"
          )

    .form__consent
      label.consent.consent-gray
        input.consent__input(
          type="checkbox"
          value="consent"
          checked="checked"
          v-model="consent"
        )
        span.consent__button
        span.consent__label(v-html="formConsentText")

    .form__button
      .button.button-background-yellow(@click="getSend()")
        .button__text(v-html="formButtonName")

  .form__block(v-show="sendForm")
    .form__title(v-html="formSuccessTitle")
    p.center(v-html="formSuccessText")
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {maxLength, minLength, required, email} from '@vuelidate/validators'
import { mapActions } from 'vuex'
export default {
  name: 'AppFormReview',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formSettingTitle",
    "formSettingSubject",

    "formTitle",
    "formButtonName",

    "formFieldName",
    "formFieldNamePlaceholder",
    "formFieldEmail",
    "formFieldEmailPlaceholder",
    "formFieldText",
    "formFieldTextPlaceholder",
    "formFieldPage",

    "formSuccessTitle",
    "formSuccessText",

    "formConsentText",

    "formRedirect",

    "formPageId",
    "formPagePagetitle"
  ],

  data () {
    return {
      fieldName: '',
      fieldEmail: '',
      fieldText: '',
      files: '',
      consent: ['consent'],
      sendForm: false,
      fieldError: false
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (this.v$.$invalid) {
        this.fieldError = true
      }

      if (!this.v$.$invalid) {
        let curData = new Date()
        let curDay = curData.getDate()
        let curMonth = curData.getMonth() + 1
        let curYear = curData.getFullYear()

        this.sendForm = !this.sendForm

        this.Send({
          setting: {
            title: this.formSettingTitle,
            subject: this.formSettingSubject,
            formDate: curDay + '.' + curMonth + '.' + curYear
          },

          fields: [
            {
              title: this.formFieldName,
              value: this.fieldName
            },
            {
              title: this.formFieldEmail,
              value: this.fieldEmail
            },
            {
              title: this.formFieldText,
              value: this.fieldText
            },
            {
              title: this.formFieldPage,
              value: this.formPagePagetitle + ' (' + this.formPageId + ')'
            },
          ],
          files: this.files
        })

        this.fieldName = this.fieldEmail = this.fieldText = ''

        setTimeout(() => {
          window.location.href = this.formRedirect
        }, 2500)
      }
    }
  },

  validations () {
    return {
      fieldName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(40)
      },

      fieldEmail: {
        required,
        email
      },

      fieldText: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(400)
      },

      consent: {
        required
      }
    }
  }
}
</script>
